import React from 'react';
import { Link, graphql } from 'gatsby';

import PropTypes from 'prop-types';

import Layout from '../layout';
import SEO from '../seo';
import PostSummary from '../post-summary';

export default function Tags({ pageContext, data }) {
    const { tag } = pageContext;
    const { edges, totalCount } = data.allMarkdownRemark;
    const tagHeader = `${totalCount} post${
        totalCount === 1 ? '' : 's'
    } tagged with "${tag}"`;

    return (
        <Layout>
            <SEO title={`Posts tagged with "${tag}"`} />
            <h1 className="header text-4xl">{tagHeader}</h1>
            <Link to="/tags" className="tag tag--large">
                View all #tags
            </Link>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {edges.map(({ node }) => {
                    return (
                        <PostSummary
                            key={node.fields.slug}
                            slug={node.fields.slug}
                            title={node.frontmatter.title}
                            date={node.frontmatter.date}
                            datePretty={node.frontmatter.datePretty}
                            tags={node.frontmatter.tags}
                            readingTime={node.fields.readingTime.text}
                            description={node.frontmatter.description}
                        />
                    );
                })}
            </div>
        </Layout>
    );
}

Tags.propTypes = {
    pageContext: PropTypes.shape({
        tag: PropTypes.string.isRequired,
    }),
};

export const query = graphql`
    query($tag: String) {
        allMarkdownRemark(
            limit: 2000
            sort: { fields: frontmatter___date, order: DESC }
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    fields {
                        slug
                        readingTime {
                            text
                        }
                    }
                    frontmatter {
                        title
                        date(formatString: "YYYY-MM-DD")
                        datePretty: date(formatString: "MMMM Do, YYYY")
                        tags
                        description
                    }
                }
            }
        }
    }
`;
