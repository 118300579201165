import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Meta from './meta';

const PostSummary = ({
    slug,
    title,
    date,
    datePretty,
    tags,
    readingTime,
    description,
}) => {
    return (
        <article>
            <header>
                <h2 className="header text-3xl">
                    <Link className="no-underline" to={slug}>
                        {title}
                    </Link>
                </h2>
                <Meta
                    date={date}
                    datePretty={datePretty}
                    tags={tags}
                    readingTime={readingTime}
                />
            </header>
            <section>
                <p className="pt-3">{description}</p>
            </section>
        </article>
    );
};

PostSummary.propTypes = {
    slug: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    datePretty: PropTypes.string,
    tags: PropTypes.array,
    readingTime: PropTypes.string,
    description: PropTypes.string,
};

PostSummary.defaultProps = {
    slug: ``,
    title: ``,
    date: ``,
    datePretty: ``,
    tags: [],
    readingTime: ``,
    description: ``,
};

export default PostSummary;
