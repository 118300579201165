import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';

const Meta = ({
    date,
    datePretty,
    lastmod,
    lastmodPretty,
    tags,
    readingTime,
}) => {
    const tagList = tags.map((tag, i) => (
        <li className="inline-block mr-2 last:mr-0" key={i}>
            <Link to={`/tags/${tag}/`} className={`tag tag--${tag}`}>
                #{tag}
            </Link>
        </li>
    ));

    return (
        <div className="font-meta text-xs uppercase">
            <span>
                <time dateTime={date} className="inline-block mr-2">
                    {datePretty}
                </time>
            </span>
            {lastmod && (
                <span className="prefix-dash">
                    Last updated:{' '}
                    <time dateTime={lastmod} className="inline-block mr-2">
                        {lastmodPretty}
                    </time>
                </span>
            )}
            <span className="whitespace-no-wrap prefix-dash">
                {readingTime}
            </span>
            <ul className="mt-1">{tagList}</ul>
        </div>
    );
};

Meta.propTypes = {
    date: PropTypes.string,
    datePretty: PropTypes.string,
    lastmod: PropTypes.string,
    tags: PropTypes.array,
};

Meta.defaultProps = {
    date: ``,
    datePretty: ``,
    lastmod: ``,
    tags: [],
};

export default Meta;
